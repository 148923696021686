import React from 'react';
import Unavailable from '../components/NotFound';


const NotFoundPage = () => {

	return(
		<Unavailable />
		
		);
}


export default NotFoundPage;